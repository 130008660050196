/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoApiModel
 */
export interface VideoApiModel {
    /**
     * 
     * @type {string}
     * @memberof VideoApiModel
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoApiModel
     */
    embedUrl?: string | null;
}

/**
 * Check if a given object implements the VideoApiModel interface.
 */
export function instanceOfVideoApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VideoApiModelFromJSON(json: any): VideoApiModel {
    return VideoApiModelFromJSONTyped(json, false);
}

export function VideoApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'embedUrl': !exists(json, 'embedUrl') ? undefined : json['embedUrl'],
    };
}

export function VideoApiModelToJSON(value?: VideoApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'thumbnailUrl': value.thumbnailUrl,
        'embedUrl': value.embedUrl,
    };
}

